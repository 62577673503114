import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import background from "../../assets/images/auth/banner-login.jpeg";
import { Redirect } from 'react-router-dom';


export class Login extends Component {
  constructor(props){
    super(props)
    this.state = {
      //email : "user1@themenate.net",
      //password : "2005ipo",
      email : "millasiete",
      password : "milla2018",
      error: false,
      mensaje: "",
    }
    //this.handleLogin = this.handleLogin.bind(this);

  }
  componentDidMount(){
		const dataLogin = JSON.parse(localStorage.getItem('dataLogin'))
    if(dataLogin !== null && dataLogin.status){
      this.props.history.push('/dashboard')
    }
  }

  
  
  render() {

    const handleFormChange = (type,value) => {
      this.setState({[type]:value})

      //console.log(this.state);
    }

    const handleLogin = async () => {

      if(this.state.email !== "" && this.state.email !== ""){

        const url = `https://routeintegrationapi.satrack.com/login`
        localStorage.removeItem("dataLogin")
      

    
        //const dataSend = new URLSearchParams()
        //dataSend.append("email", this.state.email)
        //dataSend.append("password", this.state.password)
        //dataSend.append("returnSecureToken", "true")
        
        const requestOptions = {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            "username": this.state.email, 
            "password": this.state.password,
            "returnSecureToken": true
          }),
        };
      
        const response = await fetch(url, requestOptions)
        const dataResp = await response.json()
        const client = await dataResp
        if(client.status){
          localStorage.setItem('dataLogin', JSON.stringify(client))
          //return <Redirect to='dashboard'  />
          return this.props.history.push('/dashboard')
        }else{
          this.setState({
            error: true,
            mensaje: client.error.message
          })
        }
      }else{
        this.setState({
          error: true,
          mensaje: 'Ingrese email y contraseña'
        })
      }
    }

    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="d-flex align-items-center justify-content-lg-center col-lg-6 mx-auto">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                  <img src={require("../../assets/images/logo.png")} alt="logo" />
                </div>
                <h6 className="font-weight-light">Inicia sesión</h6>
                <Form className="pt-3">
                  <Form.Group className="d-flex search-field">
                    <Form.Control value={this.state.email} type="email" onChange={(e)=> handleFormChange('email',e.target.value)} placeholder="Correo" size="lg" className="h-auto" />
                  </Form.Group>
                  <Form.Group className="d-flex search-field">
                    <Form.Control value={this.state.password} type="password" onChange={(e)=> handleFormChange('password',e.target.value) }  placeholder="Contraseña" size="lg" className="h-auto" />
                  </Form.Group>
                  <div className="mt-3">
                    {/* <Link className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" to="/dashboard">Ingresar</Link> */}
                    <div className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" onClick={handleLogin}>Ingresar</div>
                  </div>
                </Form>
                {
                  this.state.error 
                  ?
                    <div className="mt-3 text-danger">{this.state.mensaje}</div>
                  : null
                } 
              </div>
            </div>

            <div 
            className="col-lg-6 mx-auto" 
            style={{
              /* backgroundImage: require('../../assets/images/auth/banner-login.jpeg') */
              backgroundImage: `url(${background})`,
              height: '100vh'
            }} ></div>

          </div>
        </div>  
      </div>
    )
  }
}

export default Login
